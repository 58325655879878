@font-face {
  font-family: "icomoon";
  src: url("https://cdn.glitch.com/eaf94e93-186e-4a8d-83c9-13ead0effa8f%2Ficomoon.eot?1555389401345&rq1muo");
  src: url("https://cdn.glitch.com/eaf94e93-186e-4a8d-83c9-13ead0effa8f%2Ficomoon.eot?1555389401345#iefix")
      format("embedded-opentype"),
    url("https://cdn.glitch.com/eaf94e93-186e-4a8d-83c9-13ead0effa8f%2Ficomoon.ttf?1555389401313")
      format("truetype"),
    url("https://cdn.glitch.com/eaf94e93-186e-4a8d-83c9-13ead0effa8f%2Ficomoon.woff?1555389401374")
      format("woff"),
    url("https://cdn.glitch.com/eaf94e93-186e-4a8d-83c9-13ead0effa8f%2Ficomoon.svg?1555389401357")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-history:before {
  content: "\e94d";
}
.icon-equalizer:before {
  content: "\e992";
}
.icon-eye-blocked:before {
  content: "\e9d1";
}
.icon-notification:before {
  content: "\ea08";
}
.icon-question:before {
  content: "\ea09";
}
.icon-plus:before {
  content: "\ea0a";
}
.icon-minus:before {
  content: "\ea0b";
}
.icon-info:before {
  content: "\ea0c";
}
.icon-cancel-circle:before {
  content: "\ea0d";
}
.icon-play3:before {
  content: "\ea1c";
}
.icon-pause2:before {
  content: "\ea1d";
}
.icon-backward2:before {
  content: "\ea1f";
}
.icon-forward3:before {
  content: "\ea20";
}
.icon-first:before {
  content: "\ea21";
}
.icon-last:before {
  content: "\ea22";
}
.icon-previous2:before {
  content: "\ea23";
}
.icon-next2:before {
  content: "\ea24";
}
.icon-loop:before {
  content: "\ea2d";
}
.icon-shuffle:before {
  content: "\ea30";
}
.icon-checkbox-checked:before {
  content: "\ea52";
}
.icon-checkbox-unchecked:before {
  content: "\ea53";
}
.icon-share:before {
  content: "\ea7d";
}
.icon-embed:before {
  content: "\ea7f";
}
.icon-safari:before {
  content: "\eadd";
}